.ReactMarkdown {
  max-width: 870px;
  ::-webkit-scrollbar {
    width: 10px; /*对垂直流动条有效*/
    height: 10px; /*对水平流动条有效*/
  }

  /*定义滚动条的轨道颜色、内阴影及圆角*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(40, 44, 52);
    border-radius: 3px;
  }

  /*定义滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.7);
  }

  // Markdown样式
  h1 {
    color: rgb(40, 44, 52);
    margin-bottom: 0px;
    margin-top: 40px;
    font-size: 60px;
    line-height: 65px;
    font-weight: 700;
  }
  h2 {
    font-weight: bold;
    font-size: 30px;
    border-top: 1px solid #ececec;
    padding: 20px 0 0 0;
    line-height: 1.2;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    margin: 30px 0 5px;
  }
  h4 {
    font-size: 20px;
    line-height: 1.3;
    margin-top: 50px;
  }
  p {
    margin-top: 30px;
    font-size: 17px;
    line-height: 1.7;
    max-width: 42em;
  }
  p > img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }
  strong {
    font-weight: bolder;
  }
  ul {
    list-style: disc;
    margin-top: 20px;
    font-size: 16px;
    color: #1a1a1a;
    padding-left: 20px;
  }
  ol {
    margin-top: 0px;
    font-size: 16px;
    color: #1a1a1a;
    padding-left: 20px;
  }
  li {
    margin-top: 10px;
  }
  blockquote {
    background-color: rgba(255, 229, 100, 0.3);
    border-left-color: #ffe564;
    border-left-width: 9px;
    border-left-style: solid;
    padding: 20px 45px 20px 26px;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: -30px;
    margin-right: -30px;
    p {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      font-weight: 500;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
    }
  }
  mark {
    background-color: rgba(187, 239, 253, 0.3);
  }
  a {
    background-color: rgba(187, 239, 253, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: #1a1a1a;
  }
  p code,
  ul code {
    padding: 0 3px;
    font-size: 0.94em;
    word-break: break-word;
    background: rgba(255, 229, 100, 0.2);
    color: #1a1a1a;
  }
  pre {
    code {
      display: block;
      margin: 0;
      color: rgb(255, 255, 255) !important;
      padding: 10px 10px !important;
    }
  }
  table {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  td,
  th {
    border-top: 0;
    border-bottom-width: 2px;
    border: 1px solid #ddd;
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    text-align: left;
  }
}
